import { SpecialistProfile } from '../types/specialist'

const transformSpecialists = (specialists: SpecialistProfile[]) =>
  specialists.map((spec) => ({
    ...spec,
    // todo: temporary big page size fix
    // Keywords and practices from specialist currently is not in use but increase the page size significantly.
    // Keep this solution unless we figure out how to deal with big amount of data in keywords.
    keywords: spec.keywords?.filter((keyword) => keyword.isTopLevelChild && keyword.keywordType === 'specialty'),
    keywords_search: [],
    practices: [...spec.practices]
      .sort((a, b) => b.reviews.reviewsTotal - a.reviews.reviewsTotal)
      .sort((a, b) => b.practiceSortOrder - a.practiceSortOrder),
    insurers: [],
    connections: {},
    about: {},
    education: {}
  }))

export default transformSpecialists
