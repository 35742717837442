import { createContext, PropsWithChildren, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { NextRouter, useRouter } from 'next/router'
import getConfig from 'next/config'
import { DEFAULT_SEARCH_DISTANCE, NUMBER_PER_PROFILE_REQUEST } from '../../constants/search'
import { parseParamsFromPath, parseQueryFromString, transformToQuery } from '../../utils/query'
import {
  getLocationDetails,
  getLocationsAutocomplete,
  getLocationsAutocompleteSelfDatabase,
  getSearchAutocomplete,
  getSearchKeywords
} from '../../api/autocomplete'
import { getSpecialtyBySlug } from '../../api/specialties'
import { AppDataContext } from '../AppData'
import { extractLocationProps } from '../../utils/findLocation'
import { PracticeProfile } from '../../types/practice'
import { SpecialistProfile } from '../../types/specialist'
import { ProfileKeyword } from '../../types/keywords'
import {
  BuildUrlParams,
  ProfileTypes,
  SearchProfilesPageProviderFilters,
  SearchProfilesPageProviderProps,
  SearchProfilesPageProviderState,
  SearchQueries,
  SearchQueriesKeys,
  SearchTypes,
  SortType,
  AutocompleteSearchTypes
} from './types'
import { filteringProfileDataByPlan } from '../../utils/filteringDataByPlan'
import { useGetUnitsByLocale } from '../../hooks/useGetUnitsByLocale'
import { Location } from '../../types'
import { compareStrings } from '../../utils/strings'
import { useIsInitialMount } from '../../hooks/useIsInitialMount'
import transformSpecialists from '../../utils/transformSpecialists'
import apiRequest from '../../api/request'
import { useWpaTenant } from '../../hooks/useWpaTenant'
import { newSearchTypesPractices, searchTypes } from '../../constants/global'
import { useFeatureFlags } from '../../hooks/useFeatureFlags'
import { transformSearchType } from '../../utils/transformAutocomplete'
import useTranslateMessage from '../../hooks/useTranslateMessage'

const generateQuery = (values: SearchProfilesPageProviderFilters) => {
  const specialties = [...values.specialties, ...(values.extraSpecialties ? values.extraSpecialties : [])]
  const defaultQuery: Partial<SearchProfilesPageProviderFilters> = {
    distance: values.distance === 'nolimit' ? '' : values.distance,
    insurer: values.insurer,
    specialistType: values.specialistType,
    appointmentType: values.appointmentType,
    gender: values.gender,
    feeAgreed: values.feeAgreed,
    premium: values.premium,
    patientsChildren: values.patientsChildren,
    specialties,
    location: values.location,
    lat: values.lat,
    lon: values.lon,
    sortType: values.sortType,
    type: values.type,
    language: values.language,
    slots: values.slots
  }
  const filters: Record<string, number | string | string[] | any[] | boolean> = {
    ...defaultQuery,
    limit: NUMBER_PER_PROFILE_REQUEST,
    offset: (values.page - 1) * NUMBER_PER_PROFILE_REQUEST
  }

  return {
    routerQuery: transformToQuery(defaultQuery),
    apiQuery: transformToQuery(filters)
  }
}

// todo: fix complexity
// eslint-disable-next-line complexity
const getDefaultStateFromRouter = (
  router: NextRouter,
  locale: string,
  lang: string,
  currentLocation: Location,
  defaultValues?: SearchProfilesPageProviderFilters
): SearchProfilesPageProviderFilters => {
  const {
    searchString = '',
    insurer = '',
    distance = '',
    specialistType = '',
    appointmentType = '',
    gender = '',
    feeAgreed = false,
    premium = false,
    patientsChildren = false,
    language = '',
    locationId = '',
    typeOfSpecialist = '',
    profileType = ProfileTypes.practices,
    specialties = [],
    sortType = SortType.relevance,
    location = defaultValues?.location || '',
    treatment = '',
    slots = false
  } = parseQueryFromString(router.asPath)

  const [, , locationString] = parseParamsFromPath(router.asPath)
  const extMode = locationString.startsWith('ext-')
  const { lat = '', lon = '' } = extMode
    ? extractLocationProps(locationString)
    : currentLocation || {
        lat: '51.4776721',
        lon: '0.1648559'
      }

  return {
    page: Number(defaultValues?.page || router.query.page || 1),
    searchString: defaultValues?.searchString || searchString,
    distance: distance || '',
    insurer: (defaultValues?.insurer || insurer) as string,
    specialistType: (defaultValues?.specialistType as string) || specialistType,
    appointmentType: (defaultValues?.appointmentType as string) || appointmentType,
    gender: (defaultValues?.gender as string) || gender,
    feeAgreed: (defaultValues?.feeAgreed as boolean) || feeAgreed === 'true',
    premium: (defaultValues?.premium as boolean) || premium === 'false',
    slots: (defaultValues?.slots as boolean) || slots === 'true',
    patientsChildren: (defaultValues?.patientsChildren as boolean) || patientsChildren === 'true',
    specialties: (defaultValues?.specialties as string[]) || (specialties as string[]),
    language: (defaultValues?.language as string) || language,
    profileType: (defaultValues?.profileType || profileType) as ProfileTypes,
    searchType: defaultValues?.searchType || SearchTypes.practices,
    sortType: defaultValues?.sortType || (sortType as SortType),
    location: defaultValues?.location || extMode ? location : searchString || currentLocation.name[lang],
    locationId: defaultValues?.locationId || locationId,
    childLocations: [],
    breadcrumb: [],
    lat: defaultValues?.lat || lat,
    lon: defaultValues?.lon || lon,
    typeOfSpecialist: defaultValues?.typeOfSpecialist || typeOfSpecialist,
    treatment: defaultValues?.treatment || treatment,
    selectedKeyword: defaultValues?.selectedKeyword || '',
    urlKeyword: defaultValues?.urlKeyword || ''
  }
}

const getHashParams = (hash: string) => {
  const hashParams = new URLSearchParams(hash.replace('#', ''))
  return Array.from(hashParams.entries()).reduce(
    (params, [key, value]) => ({ ...params, [key]: value }),
    {} as Record<string, string>
  )
}

const SearchProfilesPageContext = createContext<SearchProfilesPageProviderState>({
  state: {
    specialists: [],
    practices: [],
    specialistsAutocomplete: [],
    practicesAutocomplete: [],
    specialties: [],
    conditionsProcedures: [],
    locations: [],
    relatedKeywords: [],
    typeOfSpecialist: [],
    // @ts-ignore //TODO: temp
    specialty: undefined,
    isMainKeywordConditionOrProcedure: false,
    totalProfiles: 0,
    filters: {
      searchType: SearchTypes.practices,
      profileType: ProfileTypes.practices,
      searchString: '',
      specialistType: '',
      distance: '',
      appointmentType: '',
      gender: '',
      feeAgreed: false,
      premium: false,
      patientsChildren: false,
      slots: false,
      language: '',
      selectedKeyword: '',
      location: '',
      locationId: '',
      parentLocation: '',
      childLocations: [],
      breadcrumb: [],
      lat: '',
      lon: '',
      insurer: '',
      page: 1,
      specialties: [],
      sortType: SortType.relevance,
      typeOfSpecialist: '',
      treatment: '',
      urlKeyword: ''
    },
    isLoading: false,
    isKeywordChanged: false,
    isTypeChanged: false,
    isLocationChanged: false,
    isEmptyResponse: false,
    clientReloaded: false
  },
  searchKeywordTitle: '',
  resetLocationsAutocomplete: () => null,
  changeFilter: (key, val) => ({ key, val }),
  changeSearch: (search) => search,
  changeKeyword: () => {},
  changeType: () => {},
  changeLocation: () => {},
  changeLocationSearch: (search, language) => ({ search, language }),
  changeLocationCoordinates: (lat, lon) => ({ lat, lon }),
  changePage: (page) => page,
  changeSpecialty: (id, slug, procedureOrCondition) => ({ id, slug, procedureOrCondition }),
  changeTypeOfSpecialist: (id) => id,
  changeSearchType: (searchType) => searchType,
  changeCurrentLocation: (locationId) => locationId,
  openProfilePage: (slug) => slug,
  reloadData: () => {},
  loadCurrentLocationByLatLon: () => Promise.resolve()
})

// todo: fix complexity
// eslint-disable-next-line complexity
function SearchProfilesPageProvider({
  children,
  specialists: initialSpecialists,
  practices: initialPractices,
  defaultFilters,
  relatedKeywords: initialRelatedKeywords,
  typeOfSpecialist: initialTypeOfSpecialist,
  isMainKeywordConditionOrProcedure,
  specialty: initSpecialty,
  totalProfiles,
  practiceSponsors,
  specialistsSponsors,
  breadcrumb,
  currentLocation,
  conditionsAndProcedures,
  specialties,
  defaultLocation,
  locationsAutocomplete,
  distance
}: PropsWithChildren<SearchProfilesPageProviderProps>) {
  const { locale, language: lang, tenantSettings } = useContext(AppDataContext)

  const tranlate = useTranslateMessage()
  const router = useRouter()
  const isInitialMount = useIsInitialMount()
  const { publicRuntimeConfig } = getConfig()
  const getUnitsByLocale = useGetUnitsByLocale()
  const { isWPA } = useWpaTenant()
  const { alternativeSearchEnabled } = useFeatureFlags()

  const parseQueryString = (queryString: string) => {
    const urlParts = queryString.split('#')[queryString.split('#').length - 1]
    const parameters = urlParts.split('&')

    return parameters.reduce((result: Record<string, string | boolean>, param) => {
      const [key, value] = param.split('=')
      if (key === 'premium') {
        result[key] = value === 'true'
        return result
      }

      result[key] = decodeURIComponent(value?.replace(/\+/g, ' '))
      return result
    }, {})
  }

  if (initSpecialty.name[lang] === 'ABSTRACT_TOP_LEVEL_KEYWORD') {
    // eslint-disable-next-line no-param-reassign
    initSpecialty.name[lang] = ''
  }

  if (initSpecialty.name[lang] === 'ABSTRACT_TOP_LEVEL_KEYWORD') {
    // eslint-disable-next-line no-param-reassign
    initSpecialty.name[lang] = ''
  }

  const [state, setState] = useState<SearchProfilesPageProviderState['state']>({
    specialists: initialSpecialists || [],
    practices: initialPractices || [],
    currentLocation,
    specialistsAutocomplete: [],
    practicesAutocomplete: [],
    practiceSponsors,
    specialistsSponsors,
    specialties: specialties || [],
    defaultLocation,
    conditionsProcedures:
      (currentLocation ? conditionsAndProcedures?.filter((item) => item.id !== 152) : conditionsAndProcedures) || [],
    relatedKeywords: initialRelatedKeywords || [],
    typeOfSpecialist: initialTypeOfSpecialist || [],
    locations: locationsAutocomplete || [],
    filters: {
      ...getDefaultStateFromRouter(router, locale, lang, currentLocation, defaultFilters),
      searchString: initSpecialty?.name?.[lang] || '',
      profileType: defaultFilters.profileType,
      breadcrumb,
      urlKeyword: defaultFilters.urlKeyword,
      distance: distance || 'nolimit',
      parentLocation: breadcrumb?.length ? breadcrumb[breadcrumb.length - 2]?.label : undefined,
      searchType:
        defaultFilters.profileType === ProfileTypes.specialists
          ? SearchTypes.specialists
          : (compareStrings(initSpecialty.name[lang], tenantSettings?.search?.DENTISTRY?.name || '') &&
              defaultFilters.urlKeyword !== 'all' &&
              SearchTypes.dentist) ||
            (defaultFilters.profileType as unknown as SearchTypes)
    },
    isLoading: false,
    isKeywordChanged: false,
    isTypeChanged: false,
    isLocationChanged: false,
    isEmptyResponse: false,
    isMainKeywordConditionOrProcedure,
    specialty: initSpecialty,
    totalProfiles,
    clientReloaded: false
  })

  const buildUrl = useCallback(
    // todo: fix complexity
    // eslint-disable-next-line complexity
    (params: BuildUrlParams): string => {
      const { resetSearch = false } = params

      const keywordSlug = params.keywordSlug || state.filters.urlKeyword
      const procedureConditionSlug =
        params.procedureConditionSlug || (params.procedureConditionSlug !== null && state.filters.treatment) || ''

      const lat = typeof params.lat !== 'undefined' ? params.lat : state.filters.lat
      const lon = typeof params.lon !== 'undefined' ? params.lon : state.filters.lon
      const location = typeof params.location !== 'undefined' ? params.location : state.filters.location
      const distanceParam = typeof params.distance !== 'undefined' && params.distance !== '0' && params.distance
      const appointmentType =
        typeof params.appointmentType !== 'undefined' ? params.appointmentType : state.filters.appointmentType
      const typeOfSpecialist =
        typeof params.typeOfSpecialist !== 'undefined' ? params.typeOfSpecialist : state.filters.specialties[1]
      const language = typeof params.language !== 'undefined' ? params.language : state.filters.language
      const gender = typeof params.gender !== 'undefined' ? params.gender : state.filters.gender
      const insurer = typeof params.insurer !== 'undefined' ? params.insurer : state.filters.insurer
      const profileType = transformSearchType(
        typeof params.profileType !== 'undefined' ? params.profileType : state.filters.profileType
      )
      const premium = typeof params.premium !== 'undefined' ? params.premium : state.filters.premium
      const slots = typeof params.slots !== 'undefined' ? params.slots : state.filters.slots
      const feeAgreed = typeof params.feeAgreed !== 'undefined' ? params.feeAgreed : state.filters.feeAgreed
      const patientsChildren =
        typeof params.patientsChildren !== 'undefined' ? params.patientsChildren : state.filters.patientsChildren

      const sortType = typeof params.sortType !== 'undefined' ? params.sortType : state.filters.sortType
      const { page } = params

      const hashQueryParams = new Map()

      if (procedureConditionSlug) {
        hashQueryParams.set('treatment', procedureConditionSlug)
      }

      if (location && location?.length) {
        hashQueryParams.set('location', location)
      }

      if (distanceParam && typeof distanceParam !== 'number' && distanceParam?.length) {
        hashQueryParams.set('distance', distanceParam)
      }

      if (sortType && sortType?.length) {
        hashQueryParams.set('sortType', sortType)
      }

      if (slots) {
        hashQueryParams.set('slots', true)
      }

      if (profileType === 'specialists') {
        if (patientsChildren) {
          hashQueryParams.set('patientsChildren', true)
        }

        if (feeAgreed) {
          hashQueryParams.set('feeAgreed', true)
        }

        if (appointmentType && appointmentType?.length) {
          hashQueryParams.set('appointmentType', appointmentType)
        }

        if (typeOfSpecialist && typeOfSpecialist.length) {
          hashQueryParams.set('typeOfSpecialist', typeOfSpecialist)
        }

        if (gender && gender?.length) {
          hashQueryParams.set('gender', gender)
        }

        if (language) {
          hashQueryParams.set('language', language)
        }
      }

      if (profileType === 'practices' && !premium) {
        hashQueryParams.set('premium', false)
      }

      if (insurer) {
        hashQueryParams.set('insurer', insurer)
      }

      let hashQueryString = ''
      if (hashQueryParams.size > 0) {
        hashQueryString = Array.from(hashQueryParams)
          .map(([key, value]) => `${key}=${value}`)
          .join('&')
      }

      let locationString = `ext-${lat}-${lon}`
      if (params.slug) {
        locationString = params.slug
      }

      let url = `/${router.locale}/find/${keywordSlug}/${locationString}/${profileType}`

      if (page && page > 1) {
        url += `/page-${page}`
      }

      if (hashQueryString && !resetSearch) {
        url += `#${hashQueryString}`
      }

      return url
    },
    // todo: clarify deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state.filters]
  )

  const checkClientReloadPossibility = useCallback(
    (hash: string) => {
      const hashParams = getHashParams(hash)

      const hashKeys = Object.keys(hashParams).sort() as SearchQueriesKeys
      const hashDistance = hashParams.distance

      const allowedQueries: SearchQueriesKeys = [SearchQueries.treatment, SearchQueries.location]

      const isInternalLocation = !!currentLocation
      const internalLocationDistance = String(currentLocation?.searchDistance)

      const isDefaultLocationDistance = isInternalLocation
        ? hashDistance === internalLocationDistance
        : hashDistance === DEFAULT_SEARCH_DISTANCE

      if (isDefaultLocationDistance) {
        allowedQueries.push(SearchQueries.distance)
      }

      const allValid = hashKeys.every((query) => allowedQueries.includes(query))

      const containsAtLeastOneValidElement = hashKeys.some((query) => allowedQueries.includes(query))

      return (allValid && containsAtLeastOneValidElement) || !hashKeys.length
    },
    [currentLocation]
  )

  const isSpecialistSearch = useMemo(() => {
    const profileType = state.filters.profileType

    return profileType === ProfileTypes.specialists || profileType === ProfileTypes.healthAndSocialCare
  }, [state.filters.profileType])

  useEffect(() => {
    const filters = defaultFilters

    const disallowClientLoading = checkClientReloadPossibility(window.location.hash)

    const { hash } = new URL(window.location.href)
    const splitedHash =
      hash
        .replace('#', '')
        .split('&')
        .map((item) => {
          const [key, value] = item.split('=')
          return {
            key,
            value
          }
        }) || []
    const filtersToAdd: Record<string, string | string[]> = {}
    splitedHash.forEach((f) => {
      filtersToAdd[f.key] = f.value
    })

    const treatmentKeyword = initialRelatedKeywords.find((item) => item.slug[0] === filtersToAdd?.treatment)
    const { typeOfSpecialist } = filtersToAdd
    const typeOfSpecialistId = typeof typeOfSpecialist !== 'undefined' ? typeOfSpecialist : undefined

    const defaultSpecialties = filters.specialties
    const specialties = (
      defaultSpecialties?.length >= 1
        ? [...defaultSpecialties, typeOfSpecialistId]
        : Array.from({ length: 2 }).fill(typeOfSpecialistId)
    ).filter(Boolean) as string[]

    setState((prev) => ({
      ...prev,
      filters: {
        ...prev.filters,
        specialties: treatmentKeyword ? [...prev.filters.specialties[0], treatmentKeyword.id.toString()] : specialties,
        ...filtersToAdd
      }
    }))

    if (!disallowClientLoading) {
      setState((prev) => ({ ...prev, isLoading: true, isEmptyResponse: false, clientReloaded: true }))
    }
    // todo: clarify deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultFilters])

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      filters: {
        ...prevState.filters,
        distance: prevState.filters.distance || (currentLocation ? currentLocation.searchDistance || 'nolimit' : '25')
      }
    }))
    // todo: clarify deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationsAutocomplete])

  const changeFilter = (key: string, value: string | number | boolean | string[]) => {
    setState((prev) => ({
      ...prev,
      isLoading: true,
      isEmptyResponse: false,
      filters: { ...prev.filters, [key]: value, page: 1 }
    }))
    const url = buildUrl({
      distance: state.filters.distance,
      [key]: value,
      slug: currentLocation?.slug,
      location: currentLocation?.name?.[lang]
    })

    window.history.pushState({}, '', url)
  }

  const changeKeyword = () => {
    setState((prev) => ({ ...prev, isKeywordChanged: true }))
  }

  const changeType = () => {
    setState((prev) => ({ ...prev, isTypeChanged: true }))
  }

  const changeLocation = () => {
    setState((prev) => ({ ...prev, isLocationChanged: true }))
  }

  const changePage = (page: number): void => {
    setState((prev) => ({ ...prev, filters: { ...prev.filters, page } }))
  }

  const openProfilePage = (slug: string): void => {
    window.open(`/${router.locale}/${isSpecialistSearch ? 'specialist' : 'practice'}/${slug}`, '_blank')
  }

  const reloadData = () => {
    setState((prev) => ({ ...prev, isLoading: true, isEmptyResponse: false }))
  }

  const changeSpecialty = async (id: string, slug: string, procedureOrCondition: boolean = false) => {
    if (procedureOrCondition) {
      const specialtyResponse = await getSpecialtyBySlug({
        language: lang,
        locale,
        slug
      })

      const parentSpecialtySlugs = specialtyResponse?.typeOfSpecialist?.map((kw) => kw?.slug?.[0])
      const keywordSlug = parentSpecialtySlugs?.includes(state.filters.urlKeyword)
        ? undefined
        : specialtyResponse?.slug[0]

      setState((prev) => ({
        ...prev,

        isLoading: true,
        isEmptyResponse: false,
        filters: {
          ...prev.filters,
          typeOfSpecialist: '',
          // distance: DEFAULT_SEARCH_DISTANCE,
          appointmentType: '',
          gender: '',
          language: '',
          patientsChildren: false,
          feeAgreed: false,
          premium: false,
          slots: false,
          insurer: '',
          page: 1,
          specialties: state.filters.specialties.includes(id)
            ? [...state.filters.specialties.filter((item) => item !== id), state.specialty.id.toString()]
            : [(prev.filters.specialties[0], id)],
          treatment: procedureOrCondition ? (slug as string) : '',
          urlKeyword: procedureOrCondition ? (slug as string) : ''
        }
      }))

      const url = buildUrl({
        slug: currentLocation?.slug,
        location: currentLocation?.name?.[lang],
        procedureConditionSlug: state.filters.specialties.includes(id) ? null : slug,
        keywordSlug
      })

      router.push('/find/[...search]', url, { shallow: true })
      return url
    }
    const specialtyResponse = await getSpecialtyBySlug({
      language: lang,
      locale,
      slug
    })

    setState((prev) => ({
      ...prev,
      isLoading: true,
      isEmptyResponse: false,
      specialty: (specialtyResponse?.id && specialtyResponse) || prev.specialty,
      typeOfSpecialist: (specialtyResponse?.id && specialtyResponse?.typeOfSpecialist) || prev.typeOfSpecialist,
      relatedKeywords: (specialtyResponse?.id && specialtyResponse?.relatedKeywords) || prev.relatedKeywords,
      filters: {
        ...prev.filters,
        // distance: DEFAULT_SEARCH_DISTANCE,
        appointmentType: '',
        gender: '',
        premium: false,
        slots: false,
        patientsChildren: false,
        feeAgreed: false,
        language: '',
        insurer: '',
        treatment: '',
        page: 1,
        urlKeyword: slug
      }
    }))
    return buildUrl({
      slug: currentLocation?.slug,
      location: currentLocation?.name?.[lang],
      keywordSlug: slug,
      procedureConditionSlug: ''
    })
  }

  const changeTypeOfSpecialist = async (id: string) => {
    const typeOfSpecialistId = id.length > 0 ? [id] : []
    setState((prev) => ({
      ...prev,
      isLoading: true,
      isEmptyResponse: false,
      filters: {
        ...prev.filters,
        specialties: [prev.filters.specialties[0], ...typeOfSpecialistId],
        typeOfSpecialist: id
      }
    }))

    window.location.href = buildUrl({
      typeOfSpecialist: id,
      slug: currentLocation?.slug,
      location: currentLocation?.name?.[lang]
    })
  }

  const searchKeywordTitle = useMemo(() => {
    const { filters, specialty } = state

    const keywordPractitioner = specialty?.practitioner?.[lang]
      ? `${specialty?.practitioner?.[lang] || specialty?.practitioner?.en}${lang === 'en' ? 's' : ''}`
      : specialty?.name?.[lang] || tranlate('global.specialist_and_doctors')

    return filters.searchType === SearchTypes.specialists || filters.searchType === SearchTypes.healthAndSocialCare
      ? keywordPractitioner
      : specialty?.name?.[lang]
  }, [lang, state, tranlate])

  const conditionProceduresKey = 'conditions-procedures'

  const changeSearch = async (search: string) => {
    let autocomplete: {
      practices: Pick<PracticeProfile, 'id' | 'images' | 'name' | 'slug'>[]
      specialists: Pick<SpecialistProfile, 'id' | 'images' | 'firstName' | 'lastName' | 'slug' | 'keywords'>[]
      specialties: Pick<ProfileKeyword, 'id' | 'iconUri' | 'name' | 'slug' | 'type'>[]
      [conditionProceduresKey]: Pick<ProfileKeyword, 'id' | 'iconUri' | 'name' | 'slug' | 'type'>[]
    }

    switch (state.filters.searchType) {
      case SearchTypes.dentist: {
        autocomplete = await getSearchAutocomplete({
          searchString: search || 'dentist',
          lang: router.locale as string,
          specialties: [tenantSettings?.search?.DENTISTRY?.id.toString() || '0'],
          searchType: AutocompleteSearchTypes.practice
        })
        break
      }
      case SearchTypes.careHome: {
        autocomplete = await getSearchAutocomplete({
          searchString: search,
          lang: router.locale as string,
          type: SearchTypes.careHome,
          searchType: AutocompleteSearchTypes.practice
        })
        break
      }
      case SearchTypes.hospital: {
        autocomplete = await getSearchAutocomplete({
          searchString: search,
          lang: router.locale as string,
          type: SearchTypes.hospital,
          searchType: AutocompleteSearchTypes.practice
        })
        break
      }
      case SearchTypes.pharmacy: {
        autocomplete = await getSearchAutocomplete({
          searchString: search,
          lang: router.locale as string,
          type: SearchTypes.pharmacy,
          searchType: AutocompleteSearchTypes.practice
        })
        break
      }
      case SearchTypes.healthAndSocialCare: {
        autocomplete = await getSearchAutocomplete({
          searchString: search,
          lang: router.locale as string,
          type: SearchTypes.healthAndSocialCare,
          searchType: AutocompleteSearchTypes.specialist
        })
        break
      }
      case SearchTypes.practices: {
        autocomplete = await getSearchAutocomplete({
          searchString: search,
          lang: router.locale as string,
          searchType: AutocompleteSearchTypes.practice
        })
        break
      }
      default: {
        autocomplete = await getSearchAutocomplete({
          searchString: search,
          lang: router.locale as string,
          searchType: AutocompleteSearchTypes.specialist
        })
      }
    }

    if (search === '' && alternativeSearchEnabled && !isWPA) {
      const defaultSearchKeywords = await getSearchKeywords({
        locale,
        keywordId: state?.specialty?.id,
        keywordType: state?.specialty?.type,
        locationId: currentLocation?.id
      })

      if (defaultSearchKeywords.specialties) {
        autocomplete.specialties = defaultSearchKeywords.specialties
      }

      if (defaultSearchKeywords.conditionsAndProcedures) {
        autocomplete[conditionProceduresKey] = defaultSearchKeywords.conditionsAndProcedures
      }
    }

    setState((prev) => ({
      ...prev,
      filters: { ...prev.filters, searchString: search },
      practicesAutocomplete: autocomplete?.practices || [],
      specialistsAutocomplete: autocomplete?.specialists || [],
      specialties: autocomplete?.specialties || [],
      conditionsProcedures:
        (currentLocation
          ? autocomplete?.[conditionProceduresKey]?.filter((x) => x.id !== 152)
          : autocomplete?.[conditionProceduresKey]) || []
    }))
  }

  const loadCurrentLocationByLatLon = async (lat: string, lon: string) =>
    getLocationsAutocomplete({
      lang: router.locale as string,
      lat,
      lon
    }).then((locations) => {
      setState((prev) => ({
        ...prev,
        locations
      }))
    })

  const changeLocationCoordinates = (
    lat: string,
    lon: string,
    location?: string,
    withRouterPush = true,
    slug?: string | null
  ) => {
    const url = buildUrl({ lat: +lat, lon: +lon, location, slug })

    if (withRouterPush) {
      window.location.href = url
    }
  }

  const changeLocationSearch = async (search: string, language: string) => {
    if (search.length > 3) {
      let locations: any = []
      setState((prev) => ({
        ...prev,
        locations
      }))
      locations = isWPA
        ? []
        : await getLocationsAutocompleteSelfDatabase({
            search,
            lang: router.locale as string,
            locationId: currentLocation?.id ?? 562,
            type: state.filters.searchType
          })
      if (!locations.length) {
        locations = await getLocationsAutocomplete({
          searchString: search,
          lang: router.locale as string,
          language
        })
      }
      setState((prev) => ({
        ...prev,
        locations
      }))
    }
  }

  const resetLocationsAutocomplete = () => {
    setState((prevState) => ({ ...prevState, locations: locationsAutocomplete }))
  }

  // TODO
  const changeCurrentLocation = async (locationId: string, locationName?: string, slug?: string | null) => {
    changeLocation()
    const locationDetails: { location: { lat: string; lng: string } } = await getLocationDetails({
      locationId,
      lang: router.locale as string
    })
    const { lat, lng } = locationDetails?.location || { lat: 0, lng: 0 }

    changeLocationCoordinates(lat, lng, locationName, true, slug)
  }

  const changeSearchType = async (searchType: SearchTypes) => {
    if (searchType === state.filters.searchType) {
      return
    }
    changeType()
    const isDentistry = searchType === SearchTypes.dentist
    const search = tenantSettings?.search
    const dentistrySpecialty = search?.DENTISTRY
    const searchTypeFromState = state.filters.searchType
    const isInNewSearchTypes = newSearchTypesPractices.includes(searchType)
    const wasInNewSearchTypes = newSearchTypesPractices.includes(searchTypeFromState!)
    const isInSearchTypes = searchTypes.includes(searchType)
    const mappedSearchType =
      alternativeSearchEnabled && !isWPA && searchType ? transformSearchType(searchType) : searchType
    const profileType = (searchType === SearchTypes.dentist
      ? SearchTypes.practices
      : mappedSearchType) as unknown as ProfileTypes

    const shouldResetSearch = isInNewSearchTypes || (isInSearchTypes && wasInNewSearchTypes)

    const location = shouldResetSearch ? defaultLocation : state.currentLocation

    const urlKeywordBuilder = (urlKeyword: string) => {
      if (isDentistry) {
        return dentistrySpecialty?.slug
      } else if (!isInNewSearchTypes) {
        return urlKeyword
      } else {
        return 'all'
      }
    }

    window.location.href = buildUrl({
      profileType,
      slug: location?.slug || `ext-${state.filters.lat}-${state.filters.lon}`,
      location: location?.name?.[lang] || state.filters.location,
      sortType: '',
      keywordSlug: urlKeywordBuilder(state.filters.urlKeyword as string),
      resetSearch: shouldResetSearch
    })

    setState((prevState) => {
      const isSearchTypeToSetDentistOrPractice =
        searchType === SearchTypes.dentist || searchType === SearchTypes.practices
      const isPreviousSearchTypeToSetDentistOrPractice =
        prevState.filters.searchType === SearchTypes.dentist || prevState.filters.searchType === SearchTypes.practices
      const isTypeChanged = !(
        isSearchTypeToSetDentistOrPractice &&
        isPreviousSearchTypeToSetDentistOrPractice &&
        searchType !== prevState.filters.searchType
      )

      return {
        ...prevState,
        isTypeChanged,
        filters: isTypeChanged ? prevState.filters : { ...prevState.filters, searchType }
      }
    })
  }

  useEffect(() => {
    if (!isInitialMount) {
      changeLocationSearch(state.filters.location, lang as string)
      changeSearch(state.filters.searchString)
      if (state.filters.searchType === tenantSettings?.search?.DENTISTRY?.slug || '') {
        changeSpecialty(
          tenantSettings?.search?.DENTISTRY?.id.toString() || '',
          tenantSettings?.search?.DENTISTRY?.slug || ''
        )
      }
      if (state.filters.searchType === tenantSettings?.search?.CARE_HOME?.slug || '') {
        changeSpecialty(
          tenantSettings?.search?.CARE_HOME?.id.toString() || '',
          tenantSettings?.search?.CARE_HOME?.slug || ''
        )
      }
    }
    // todo: clarify deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.filters.searchType])

  const getPageIndex = useCallback(() => {
    try {
      const pageSearch = router.query.search as string[]
      return pageSearch?.pop()?.replace('page-', '')
    } catch (e) {
      return '0'
    }
  }, [router.query.search])

  useEffect(() => {
    const disallowClientLoading = checkClientReloadPossibility(window.location.hash)

    if (disallowClientLoading) {
      return
    }

    setState((prev) => ({ ...prev, isLoading: true, isEmptyResponse: false, clientReloaded: true }))
  }, [checkClientReloadPossibility, router.asPath])

  // Disabled complexity of arrow function eslint check
  // eslint-disable-next-line complexity
  useEffect(() => {
    const extendableFiltersObject = {
      distance: state.filters.distance || 'nolimit',
      insurer: state.filters.insurer || '',
      specialistType: state.filters.specialistType || '',
      appointmentType: state.filters.appointmentType || '',
      gender: state.filters.gender || '',
      feeAgreed: state.filters.feeAgreed || false,
      premium: state.filters.premium || false,
      slots: state.filters.slots || false,
      patientsChildren: state.filters.patientsChildren || false,
      location: state.filters.location || '',
      treatment: state.filters.treatment || '',
      language: state.filters.language || '',
      typeOfSpecialist: state.filters.typeOfSpecialist || '',
      lat: state.filters.lat,
      lon: state.filters.lon
    }

    const loadSpecialists = async (params: { type?: string; extraSpecialties?: Array<string> } = {}) => {
      const pageIndex = getPageIndex()

      const filtersObjectFromURL = parseQueryString(window.location.hash)

      const filtersFromUrl = { ...extendableFiltersObject, ...filtersObjectFromURL }

      const specialtyResponse = filtersFromUrl.treatment
        ? await getSpecialtyBySlug({
            language: lang,
            locale,
            slug: filtersFromUrl.treatment,
            distance: extendableFiltersObject.distance.toString() || distance.toString(),
            lat: extendableFiltersObject?.lat,
            lon: extendableFiltersObject?.lon
          })
        : null

      const queryFilters = {
        ...state.filters,
        ...filtersFromUrl,
        page: Number(pageIndex) || 1,
        selectedKeyword: specialtyResponse?.name[lang] || state.filters.selectedKeyword || '',
        specialties: specialtyResponse
          ? [specialtyResponse?.id.toString(), state.filters.typeOfSpecialist].filter((x) => !!x?.length)
          : state.filters.specialties,
        ...params
      }

      const { apiQuery } = generateQuery({
        ...queryFilters,
        page: Number(pageIndex) || 1,
        specialties: queryFilters.specialties.filter((s) => s !== undefined),
        distance: getUnitsByLocale(filtersFromUrl.distance).queryDistance
      })
      const apiBasePath = `${publicRuntimeConfig.BASIC_API_URL}/${router.locale}/search/specialists`
      let specialistFetchUrl = apiBasePath
      if (apiQuery) {
        specialistFetchUrl += `?${apiQuery}`
      }

      const specialistsResponse = await apiRequest(specialistFetchUrl).then((r) => r.json())
      const specialistsResponseData = specialistsResponse?.data || []
      const filteredProfilesData = specialistsResponseData.map((specialist: SpecialistProfile) =>
        filteringProfileDataByPlan({ specialist })
      )

      setState((prevState) => ({
        ...prevState,
        specialists: transformSpecialists(filteredProfilesData),
        filters: { ...state.filters, ...queryFilters },
        specialty: specialtyResponse || prevState.specialty,
        isLoading: false,
        isEmptyResponse: filteredProfilesData.length === 0,
        totalProfiles: specialistsResponse.total || 0
      }))
    }
    const loadPractices = async (params: { type?: string; extraSpecialties?: Array<string> } = {}) => {
      const pageIndex = getPageIndex()

      const filtersObjectFromURL = parseQueryString(window.location.hash)

      const filtersFromUrl = { ...extendableFiltersObject, ...filtersObjectFromURL }

      const specialtyResponse = filtersFromUrl.treatment
        ? await getSpecialtyBySlug({
            language: lang,
            locale,
            slug: filtersFromUrl.treatment
          })
        : null

      const queryFilters = {
        ...state.filters,
        ...filtersFromUrl,
        page: Number(pageIndex) || 1,
        selectedKeyword: specialtyResponse?.name[lang] || state.filters.selectedKeyword || '',
        specialties: specialtyResponse
          ? [specialtyResponse?.id.toString()]
          : state.filters.specialties.filter((specialty) => specialty !== undefined)
      }

      const { apiQuery } = generateQuery({
        ...queryFilters,
        ...params,
        page: Number(pageIndex) || 1,
        distance: getUnitsByLocale(queryFilters.distance).queryDistance
      })

      let fetchUrl = `${publicRuntimeConfig.BASIC_API_URL}/${router.locale}/search/practices`
      if (apiQuery) {
        fetchUrl += `?${apiQuery}`
      }

      const practicesResponse = await apiRequest(fetchUrl).then((r) => r.json())
      const practicesResponseData = practicesResponse?.data || []
      const filteredProfilesData = practicesResponseData.map((practice: PracticeProfile) =>
        filteringProfileDataByPlan({ practice })
      )

      setState((prevState) => ({
        ...prevState,
        practices: filteredProfilesData,
        filters: { ...state.filters, ...queryFilters },
        specialty: specialtyResponse || prevState.specialty,
        isLoading: false,
        isEmptyResponse: filteredProfilesData.length === 0,
        totalProfiles: practicesResponse.total || 0
      }))
    }

    if (state.isLoading) {
      switch (state.filters.searchType) {
        case SearchTypes.specialists: {
          loadSpecialists()
          break
        }
        case SearchTypes.practices: {
          loadPractices()
          break
        }
        case SearchTypes.dentist: {
          loadPractices({ extraSpecialties: [tenantSettings?.search?.DENTISTRY?.id.toString() || ''] })
          break
        }
        case SearchTypes.careHome: {
          loadPractices({
            type: SearchTypes.careHome
          })
          break
        }
        case SearchTypes.hospital: {
          loadPractices({
            type: SearchTypes.hospital
          })
          break
        }
        case SearchTypes.pharmacy: {
          loadPractices({
            type: SearchTypes.pharmacy
          })
          break
        }
        case SearchTypes.healthAndSocialCare: {
          loadSpecialists({
            type: SearchTypes.healthAndSocialCare
          })
          break
        }
        default: {
          loadPractices()
        }
      }
    }
    // todo: clarify deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.isLoading, router.asPath])

  return (
    <SearchProfilesPageContext.Provider
      value={{
        state,
        searchKeywordTitle,
        reloadData,
        changeSearch,
        changeLocationSearch,
        changeCurrentLocation,
        changeFilter,
        changeKeyword,
        changeType,
        changeLocation,
        changePage,
        changeSpecialty,
        changeTypeOfSpecialist,
        changeSearchType,
        changeLocationCoordinates,
        openProfilePage,
        resetLocationsAutocomplete,
        loadCurrentLocationByLatLon,
        buildUrl
      }}
    >
      {children}
    </SearchProfilesPageContext.Provider>
  )
}

export { SearchProfilesPageProvider, SearchProfilesPageContext }
