import { Box, Typography, BoxProps } from '@hermes/web-components'
import { FC } from 'react'
import useTranslateMessage from '../../../../hooks/useTranslateMessage'

interface NextAvailableSlotLabelProps extends BoxProps {
  withoutDateTime?: boolean
}

const NextAvailableSlotLabel: FC<NextAvailableSlotLabelProps> = ({ children, withoutDateTime, ...props }) => {
  const translate = useTranslateMessage()

  return (
    <Box ml="2px" mr="auto" display="flex" gap="8px" alignItems="center" {...props}>
      <Box width="16px" height="16px" bgcolor="#00BF7C" borderRadius="50%" />
      {withoutDateTime ? (
        <Typography color="#4C5870" lineHeight="20px">
          {translate('online_booking_available')}
        </Typography>
      ) : (
        <>
          <Typography color="#4C5870" lineHeight="20px">
            {translate('booking.next_available_slot')}
          </Typography>{' '}
          <Typography color="#11245A" fontWeight={500} lineHeight="20px">
            {children}
          </Typography>
        </>
      )}
    </Box>
  )
}

export default NextAvailableSlotLabel
