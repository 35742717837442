import getConfigVariable from '../utils/getConfigVariable'
import { ProfileKeyword } from '../types/keywords'
import { fixProfileKeyword } from '../utils/correctorKeywordName'
import apiRequest from './request'

const basePath = getConfigVariable('BASIC_API_URL')

export const getSpecialties = async ({ lang }: { lang: string }): Promise<ProfileKeyword[]> =>
  apiRequest(`${basePath}/${lang}/filters/specialties`).then((r) => r.json())

export const getSpecialtyBySlug = async ({
  language,
  locale,
  slug,
  distance,
  lat,
  lon
}: {
  language: string
  locale: string
  slug: string
  distance?: string
  lat?: string
  lon?: string
}): Promise<ProfileKeyword | null> => {
  const searchParams = new URLSearchParams()
  if (distance) {
    searchParams.append('distance', distance)
  }
  if (lat) {
    searchParams.append('lat', lat)
  }
  if (lon) {
    searchParams.append('lon', lon)
  }

  const queryParams = searchParams.toString()

  let url = `${basePath}/${locale}/search/keyword/${slug}`
  if (queryParams?.length) {
    url += `?${queryParams}`
  }

  const response = await apiRequest(url).then((r) => r.json())
  if (!response || (response.statusCode && response.statusCode !== 200)) {
    console.error('Error getSpecialtyBySlug: ', response)
    return null
  }
  return fixProfileKeyword(response, language)
}
