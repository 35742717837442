import { transformToQuery } from '../utils/query'
import getConfigVariable from '../utils/getConfigVariable'
import { PracticeProfile } from '../types/practice'
import { SpecialistProfile } from '../types/specialist'
import { ProfileKeyword } from '../types/keywords'
import apiRequest from './request'
import { AutocompleteSearchTypes, ProfileTypes, SearchTypes } from '../providers/SearchProfilesPage/types'

const basePath = getConfigVariable('BASIC_API_URL')
const internalApiUrl = getConfigVariable('INTERNAL_API_URL')

export const getLocationsAutocomplete = async ({
  searchString,
  lang,
  language,
  lat,
  lon
}: {
  searchString?: string
  lang: string
  language?: string
  lat?: string
  lon?: string
}) => {
  const query = transformToQuery({
    searchString,
    language,
    lat,
    lon
  })
  return apiRequest(`${basePath}/${lang}/search/autocomplete-locations?limit=${10}&${query}`).then((r) => r.json())
}

export const getLocationsAutocompleteSelfDatabase = async ({
  locationId,
  lang,
  keywordId,
  search,
  type
}: {
  locationId?: number
  lang: string
  keywordId?: number
  search?: string
  type?: ProfileTypes | SearchTypes
}) => {
  const searchParams = new URLSearchParams()

  if (keywordId && keywordId !== 1) {
    searchParams.append('keywordId', keywordId.toString())
  }
  if (search?.length) {
    searchParams.append('search', search)
  }
  if (type) {
    searchParams.append('type', type)
  }

  const queryParams = searchParams.toString()

  let url = `${internalApiUrl}/${lang}/api/search/locations`

  if (locationId) {
    url += `/${locationId}`
  }

  if (queryParams?.length) {
    url += `?${queryParams}`
  }

  return apiRequest(url).then((r) => r.json())
}

export const getLocationDetails = async ({ locationId, lang }: { locationId: string; lang: string }) =>
  apiRequest(`${basePath}/${lang}/search/location-details/${locationId}`).then((r) => r.json())

export const getSearchAutocomplete = async ({
  searchString,
  lang,
  type,
  specialties,
  searchType
}: {
  searchString: string
  lang: string
  type?: string
  specialties?: string[]
  searchType: AutocompleteSearchTypes
}): Promise<{
  practices: Pick<PracticeProfile, 'id' | 'images' | 'name' | 'slug'>[]
  specialists: Pick<SpecialistProfile, 'id' | 'images' | 'firstName' | 'lastName' | 'slug' | 'keywords'>[]
  specialties: Pick<ProfileKeyword, 'id' | 'iconUri' | 'name' | 'slug' | 'type'>[]
  'conditions-procedures': Pick<ProfileKeyword, 'id' | 'iconUri' | 'name' | 'slug' | 'type'>[]
}> => {
  const query = transformToQuery({ searchString, type, specialties, searchType })
  return apiRequest(`${basePath}/${lang}/search/autocomplete?limit=${10}&${query}`).then((r) => r.json())
}

export const getSearchKeywords = async ({
  keywordId,
  keywordType,
  locationId,
  locale
}: {
  keywordType?: string
  locationId?: number
  keywordId?: number
  locale: string
}): Promise<{
  practices: Pick<PracticeProfile, 'id' | 'images' | 'name' | 'slug'>[]
  specialists: Pick<SpecialistProfile, 'id' | 'images' | 'firstName' | 'lastName' | 'slug' | 'keywords'>[]
  specialties: Pick<ProfileKeyword, 'id' | 'iconUri' | 'name' | 'slug' | 'type'>[]
  conditionsAndProcedures: Pick<ProfileKeyword, 'id' | 'iconUri' | 'name' | 'slug' | 'type'>[]
}> => {
  let url = `${internalApiUrl}/${locale}/api/keywords?`
  if (keywordType) {
    url += `keyword_type=${keywordType}&`
  }
  if (locationId) {
    url += `locationId=${locationId}`
  }
  if (keywordId && keywordId !== 1) {
    url += `&keywordId=${keywordId}`
  }
  return apiRequest(url).then((r) => r.json())
}
