import { SearchTypes, AlternativeNavigationSearchTypes } from '../providers/SearchProfilesPage/types'
import { SpecialistProfile } from '../types/specialist'
import { PracticeProfile } from '../types/practice'
import { ProfileKeyword } from '../types/keywords'

type SearchAutocompleteItem = {
  name: string
  id: number
  slug: string
  logo: string
  subtitle?: string
  type?: string
}

export const transformAutocompleteSpecialist = (
  profile: Pick<SpecialistProfile, 'id' | 'images' | 'firstName' | 'lastName' | 'slug' | 'keywords'>,
  language: string
): SearchAutocompleteItem => ({
  name: `${profile?.firstName?.[language]} ${profile.lastName?.[language]}`.trim() || '',
  id: profile?.id,
  slug: profile?.slug,
  logo: profile?.images?.logo,
  subtitle: profile?.keywords?.find((f) => f.keywordType === 'specialty')?.name?.[language]
})

export const transformAutocompletePractices = (
  profile: Pick<PracticeProfile, 'id' | 'images' | 'name' | 'slug'>,
  language: string
): SearchAutocompleteItem => ({
  name: profile?.name?.[language] || '',
  id: profile?.id,
  slug: profile?.slug,
  logo: profile?.images?.logo,
  subtitle: ''
})

export const alternativeTransformAutocompletePractices = (
  profile: Pick<PracticeProfile, 'id' | 'images' | 'name' | 'slug'>,
  language: string
): SearchAutocompleteItem => ({
  name: profile?.name?.[language] || '',
  id: profile?.id,
  slug: profile?.slug,
  logo: profile?.images?.cover,
  subtitle: ''
})

export const transformAutocompleteKeyword = (
  keyword: Pick<ProfileKeyword, 'id' | 'iconUri' | 'name' | 'slug' | 'type'>,
  language: string
): SearchAutocompleteItem => ({
  name: keyword?.name?.[language] || '',
  id: keyword?.id,
  slug: keyword?.slug[0],
  logo: keyword?.iconUri,
  subtitle: '',
  type: keyword.type
})

export const transformSearchType = (type: string): AlternativeNavigationSearchTypes => {
  const obj: { [key: string]: AlternativeNavigationSearchTypes } = {
    [SearchTypes.specialists]: AlternativeNavigationSearchTypes.specialists,
    [SearchTypes.practices]: AlternativeNavigationSearchTypes.practices,
    [SearchTypes.dentist]: AlternativeNavigationSearchTypes.dentist,
    [SearchTypes.healthAndSocialCare]: AlternativeNavigationSearchTypes.healthAndSocialCare,
    [SearchTypes.hospital]: AlternativeNavigationSearchTypes.hospital,
    [SearchTypes.pharmacy]: AlternativeNavigationSearchTypes.pharmacy,
    [SearchTypes.careHome]: AlternativeNavigationSearchTypes.careHome
  }

  return obj[type] || type
}
